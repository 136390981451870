import React from "react";
import Layout from "../components/common/Layout";
import HeroSection from "../components/common/HeroSection";
import FormCon from "../components/Form/FormCon";
import FieldArray from "../components/Form/FieldArray";
import LoadBtn from "../components/Form/LoadBtn";

const contactForm = {
  inputs: {
    type: {
      name: "type",
      type: "select",
      options: [
        {
          title: "Enquiry",
          value: "Enquiry",
        },
        {
          title: "Careers",
          value: "Careers",
        },
      ],
    },
    name: {
      name: "name",
      type: "text",
      placeholder: "Enter your name",
    },
    email: {
      name: "email",
      type: "email",
      placeholder: "Enter your email",
    },
    number: {
      name: "number",
      type: "tel",
      placeholder: "Enter your phone number",
    },
  },
  allIds: ["type", "name", "email", "number"],
  values: { type: "Enquiry" },
};

function Contact() {
  return (
    <Layout>
      <HeroSection title="Contact Us" />
      <section className="residence-section">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="pb-4">
                <h5 className="residence-section-title">Address</h5>
                <p>
                  Baashyaam Constructions Pvt. Ltd.
                  <br />
                  4th Floor, No.87 G.N. Chetty Road,
                  <br />
                  T.Nagar, Chennai, 600 017.
                </p>
                <iframe
                  title="google-maps"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.8428696551146!2d80.23938361482276!3d13.045671890807329!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526650dc5e5465%3A0x687f50d8c8c2a712!2sBaashyaam%20Constructions!5e0!3m2!1sen!2sin!4v1580453063144!5m2!1sen!2sin"
                  height={350}
                  frameBorder={0}
                  style={{ border: 0, width: "100%" }}
                  allowFullScreen
                />
              </div>
              <div className="pb-4">
                <h5 className="residence-section-title">Contact Details</h5>
                <div className="mb-2">
                  <a
                    className="d-flex align-items-center link-color"
                    href="tel:+919840477777"
                  >
                    <span className="mr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-phone"
                      >
                        <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                      </svg>
                    </span>
                    <span>+91 9840477777</span>
                  </a>
                </div>
                <div>
                  <a
                    className="d-flex align-items-center link-color"
                    href="mailto:enquiry@bashyamgroup.com"
                  >
                    <span className="mr-3">
                      <svg
                        viewBox="0 0 24 24"
                        width={18}
                        height={18}
                        stroke="currentColor"
                        strokeWidth={2}
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="css-i6dzq1"
                      >
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                        <polyline points="22,6 12,13 2,6" />
                      </svg>
                    </span>
                    <span>enquiry@bashyamgroup.com</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h4 className="mb-3 text-center font-weight-bold">
                    Get In Touch
                  </h4>
                  <FormCon
                    resetOnSuccess
                    form={contactForm}
                    config={{
                      url: "bashyam/",
                      method: "POST",
                    }}
                    renderForm={({ fetching }) => (
                      <>
                        <FieldArray />
                        <div className="form-group">
                          <LoadBtn
                            fetching={fetching}
                            className="btn btn-block btn-logo-color"
                          >
                            Submit
                          </LoadBtn>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Contact;
